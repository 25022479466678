.map-editor {
  padding: 40px 0;

  // select {
  //   border: 1px solid #2c2c2c;
  //   padding: 12px 30px;
  //   border-radius: 15px;
  // }

  &__step-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__select-category {
    margin-bottom: 30px;
  }

  &__categories {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__btns {
    display: flex;
    gap: 30px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 12px 30px;

    border: 1px solid var(--base-black);

    color: var(--base-black);

    width: 180px;
    height: 45px;


    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &__confirm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--base-black);
    color: var(--base-white);
    border-radius: 8px;
    padding: 12px 30px;

    width: 180px;
    height: 45px;

    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &__scientific-fields {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__select-scientific-field {
    margin-bottom: 30px;
  }

  &__city {
    margin-bottom: 30px;
  }

  &__select-city {
    margin-bottom: 30px;
  }

  &__select-map {
    margin-bottom: 30px;
  }

  &__step {
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__add-btn {
    margin-left: 0;
    margin-top: 40px;

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__select-map {
    button {
      margin-left: 0;
    }
  }

  &__card {
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    // input {
    //   border: 1px solid #2c2c2c;
    //   padding: 12px 20px;
    //   border-radius: 15px;
    //   margin-left: 30px;
    // }

    // textarea {
    //   border: 1px solid #2c2c2c;
    //   padding: 12px 20px;
    //   border-radius: 15px;
    //   margin-left: 30px;
    // }

    button {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &__desc {
    margin-bottom: 15px;
  }

  &__label {
    margin-bottom: 10px;
  }

  &__add-city-btn {
    margin-bottom: 30px;
    display: block;
    // background: #fff;
    color: var(--base-black);
    // border: 1px solid #2c2c2c;
    border-radius: 15px;
    // padding: 12px 30px;

    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;

    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &__add-city {
    display: none;
    // input {
    //   border: 1px solid #2c2c2c;
    //   padding: 12px 20px;
    //   border-radius: 15px;
    //   margin-left: 30px;
    // }

    &.is-active {
      display: block;
    }
  }

  &__select-city-select {
    margin-bottom: 20px;
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &__new-point {
    background-color: var(--category-800);
    border: 1px solid var(--base-black);

    position: absolute;

    width: 10px;
    height: 10px;

    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .react-transform-wrapper.transform-component-module_wrapper__SPB86 {
    max-width: 1600px;
    height: 550px;

    // max-width: 100%;
    // width: 100%;
    height: unset;
    margin: 0 auto;

    @media (max-width: 1440px) {
      height: 790px;
    }

    @media (max-width: 1160px) {
      height: 749px;
    }

    @media (max-width: 768px) {
      max-width: unset;
      width: 100%;
      height: 579px;
      margin: 0;
    }
  }

  .transform-component-module_content__FBWxo {
    max-width: 1600px;
    height: 550px;

    .map-edit__svg {
      width: 100%;
      height: 550px;
    }

    @media (max-width: 768px) {
      max-width: unset;
    }

    // svg {
    //   width: 1444px;
    //   height: 816px;
    // }
  }
}

// .react-transform-wrapper.transform-component-module_wrapper__SPB86 {
//   max-width: 1600px;
//   height: unset;
//   margin: 0 auto;

//   @media (max-width: 1440px) {
//     height: 790px;
//   }

//   @media (max-width: 1160px) {
//     height: 749px;
//   }

//   @media (max-width: 768px) {
//     max-width: unset;
//     width: 100%;
//     height: 579px;
//     margin: 0;
//   }
// }

// .transform-component-module_content__FBWxo {
//   max-width: 1600px;

//   @media (max-width: 768px) {
//     max-width: unset;
//   }

//   // svg {
//   //   width: 1444px;
//   //   height: 816px;
//   // }
// }

// .map-container {
//   width: 1444px;
//   height: 816px;

//   width: 1200px;
//   height: 600px;

//   img {
//     // width: 1444px;
//     // height: 816px;

//     width: 1200px;
//     height: 600px;
//   }
// }
