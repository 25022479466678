.cities-editor {
  padding: 40px 0;
  width: 100%;

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__list {
    margin-bottom: 30px;
  }

  &__edit {
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
