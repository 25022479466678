.custom-select {
  cursor: pointer;
  position: relative;

  &__radio {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 2px solid var(--base-black);
    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__radio-check {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: var(--base-secondary);

    transform: scale(0);

    transition: transform 0.3s ease;

    &.is-active {
      transform: scale(1);
    }
  }
}

.select-box {
  border-radius: 35px;
  padding: 10px 30px;
  width: 385px;
  height: 70px;
  background: var(--base-white);
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;
  color: #2c2c2c;
  position: relative;
  z-index: 10;

  display: flex;

  align-items: center;

  justify-content: space-between;

  transition: all 0.3s ease-in-out;

  @media (max-width: 1440px) {
    width: 373px;
    font-size: 18px;
  }

  @media (max-width: 840px) {
    width: 340px;
  }

  @media (max-width: 768px) {
    width: 290px;
    height: 50px;

    font-size: 16px;
    line-height: 140%;
    padding: 14px 20px;
  }

  @media (max-width: 385px) {
    font-size: 12px;
    width: 225px;
  }


  &.is-active {
    border-radius: 35px 35px 0 0;

    @media (max-width: 768px) {
      border-radius: 0 0 30px 30px;
    }
  }
}

.options-wrapper {
  padding: 0 32px 24px 32px;

  transition: all 0.3s ease-in-out;
  border-radius: 30px;
  position: absolute;
  width: 385px;

  background: var(--base-white);

  max-height: 0;
  overflow: hidden;

  font-weight: 600;
  font-size: 20px;
  line-height: 250%;
  text-transform: uppercase;
  color: #2c2c2c;
  border-radius: 0 0 35px 35px;

  transform: translateY(-39px);

  @media (max-width: 1440px) {
    width: 373px;
  }

  @media (max-width: 1160px) {
    font-size: 18px;
    line-height: 140%;
  }

  @media (max-width: 840px) {
    width: 340px;
  }

  @media (max-width: 768px) {
    width: 290px;
    font-size: 16px;
    transform: translateY(-21px);
    border-radius: 35px 35px 0 0;
    padding: 14px 20px 15px;
    bottom: 0;
  }

  @media (max-width: 385px) {
    width: 225px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &.is-active {
    max-height: 370px;
    transform: translateY(-2px);

    @media (max-width: 768px) {
      max-height: 344px;
      transform: translateY(-35px);
    }
  }
}

.options {
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  max-height: 280px;
  overflow-y: scroll;

  @media (max-width: 768px) {
    gap: 15px;
    max-height: 288px;
  }
}

.option {
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--base-black);
  text-transform: none;

  @media (max-width: 1160px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 385px) {
    font-size: 12px;
  }
}

.option__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
  width: 34px;
  height: 34px;

  border: 2px solid var(--base-black);
}

.custom-select__icon {
  transition: transform 0.3s ease-in-out;
  &.is-active {
    transform: rotate(-180deg);
  }
}
