.header {
  &__wrapper {
    background: var(--base-white);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: 576px) {
      gap: 10px;
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: 268px 369px;
    background: var(--base-secondary);

    padding-right: max(20px, calc((100% - 1600px) / 2));

    @media (max-width: 1440px) {
      grid-template-columns: 268px 333px;
      padding-right: max(20px, calc((100% - 1160px) / 2));
    }

    @media (max-width: 1160px) {
      grid-template-columns: 197px 272px;
      padding-right: max(20px, calc((100% - 768px) / 2));
    }

    @media (max-width: 768px) {
      grid-template-columns: 160px 118px;
      padding-right: max(20px, calc((100% - 576px) / 2));
    }

    @media (max-width: 576px) {
      display: flex;
      padding-right: 0;
    }

    transition: background-color 0.3s ease;

    &--navy {
      background: var(--base-bg);

      .header__account a {
        color: var(--base-bg);
      }

      @media (max-width: 768px) {
        background: var(--base-secondary);
        .header__account a {
          color: var(--base-white);
        }
      }
    }
  }

  &__logo {
    min-width: 204px;
    min-height: 60px;
    padding-left: max(20px, calc((100% - 1600px) / 2));
    flex-shrink: 0;
    display: flex;
    align-items: center;

    @media (max-width: 1440px) {
      padding-left: max(20px, calc((100% - 1160px) / 2));
    }

    @media (max-width: 1160px) {
      grid-template-columns: 197px 272px;
      padding-left: max(20px, calc((100% - 768px) / 2));
    }

    @media (max-width: 768px) {
      padding-left: max(15px, calc((100% - 576px) / 2));
      min-width: 150px;
      min-height: 45px;
    }

    @media (max-width: 390px) {
      min-width: 130px;
      min-height: 40px;
    }

    img {
      display: flex;

      flex-shrink: 0;
      min-width: 204px;
      max-width: 204px;
      min-height: 60px;
      max-height: 60px;

      @media (max-width: 768px) {
        min-width: unset;
        max-width: 150px;
        min-height: unset;
        max-height: 45px;
      }

      @media (max-width: 576px) {
        min-width: unset;
        max-width: 115px;
        min-height: unset;
        max-height: 33px;
      }
    }
  }

  &__about {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--base-white);
    background: var(--base-main);
    padding: 46px;

    display: flex;
    justify-content: center;

    @media (max-width: 1160px) {
      font-size: 18px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      padding: 26.5px 10px;
    }

    @media (max-width: 576px) {
      width: 155px;
    }

    @media (max-width: 390px) {
      width: unset;
      max-width: 155px;
      min-width: 112px;
    }

    a {
      color: var(--base-white);
      text-decoration: none;

      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__account {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: end;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;

    @media (max-width: 1160px) {
    }

    // &:hover {
    //   .header__announce {
    //     opacity: 1;
    //     visibility: visible;
    //   }
    // }

    a {
      display: block;

      color: var(--base-black);

      text-decoration: none;

      transition: color 0.3s ease;

      @media (max-width: 1160px) {
        font-size: 18px;
      }

      @media (max-width: 576px) {
        margin: 0 auto;
      }
    }

    @media (max-width: 576px) {
      width: 75px;
    }
  }

  @media (hover: hover) {
    .header__account:hover {
      // color: var(--base-bg);
      .header__announce {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__nav-icon {
    display: none;
    width: 22px;
    height: 25px;
    flex-shrink: 0;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__nav-item-text {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__announce {
    position: absolute;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    text-transform: none;
    color: var(--base-white);

    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    height: 100%;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.3s ease, visibility 0.3s ease;

    cursor: default;

    @media(max-width: 768px) {
      justify-content: center;
    }

    p {
      max-width: 180px;
    }

    &.is-active {
      // @media (max-width: 1160px) {
      //   // transform: translateY(0);
      //   opacity: 1;
      //   visibility: visible;
      // }

      @media (max-width: 768px) {
        transform: translateY(0);
      }
    }

    @media (max-width: 768px) {
      top: 75.4px;
      position: fixed;
      width: 100%;
      height: 75px;

      right: 0;
      z-index: 1000;

      opacity: 1;
      visibility: visible;
      background-color: var(--base-bg);

      transform: translateY(-270px);

      transition: all 0.5s ease;
    }

    //   @media (max-width: 768px) {
    //     width: 376px;

    //     top: 75.4px;
    //   }

    //   @media (max-width: 576px) {
    //     width: 100%;
    //   }
  }
}
