.footer {
  &__bottom {
    padding: 100px 0;
    background: var(--base-main);

    @media (max-width: 1160px) {
      padding: 70px 0;
    }

    @media (max-width: 768px) {
      padding: 50px 0;
    }
  }

  &__list {
    display: flex;
    column-gap: 40px;
    row-gap: 50px;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;

    @media (max-width: 768px) {
      row-gap: 40px;
    }

    @media (max-width: 370px) {
      column-gap: 10px;
    }
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    color: var(--base-white);

    transition: opacity .3s ease;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__logo {
    width: 221px;
    height: 47px;

    @media (max-width: 768px) {
      width: 129px;
      height: 27px;
    }

    @media (max-width: 330px) {
      width: 100px;
      height: 19px;
    }
  }

  &__policy {
    @media (max-width: 521px) {
      display: inline-block;
      max-width: 163px;
    }
  }
}
