.card-editor {
  &.is-active {
    .card-editor__wrapper {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      // transform: scale(1);
    }
  }
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    // transform: scale(0);

    transition: all 0.3s ease;

    // &.is-active {
    // }
  }

  &__card {
    position: relative;
    width: 800px;
    max-height: 600px;
    background-color: var(--base-white);

    overflow-y: scroll;

    padding: 40px;

    form {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  &__close-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1001;

    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
