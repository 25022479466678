.filters {
  position: absolute;
  z-index: 10;
  top: 50px;
  width: 100%;

  padding-right: max(20px, calc((100% - 1600px) / 2));
  padding-left: max(20px, calc((100% - 1600px) / 2));

  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (max-width: 1440px) {
    padding-right: max(20px, calc((100% - 1160px) / 2));
    padding-left: max(20px, calc((100% - 1160px) / 2));
  }

  @media (max-width: 1160px) {
    padding-right: max(20px, calc((100% - 768px) / 2));
    padding-left: max(20px, calc((100% - 768px) / 2));
  }

  @media (max-width: 768px) {
    padding-right: max(15px, calc((100% - 576px) / 2));
    padding-left: max(15px, calc((100% - 576px) / 2));
  }

  @media (max-width: 390px) {
    padding-right: 20px;
  }

  @media (max-width: 768px) {
    top: unset;
    bottom: 20px;
    display: none;

    &.is-active {
      display: flex;
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: 1160px) {
      align-items: start;
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 15px;
    }
  }

  &__scientific-fields {
    display: flex;
    gap: 20px;

    // opacity: 0;
    // visibility: hidden;
    // pointer-events: none;

    display: none;

    transition: opacity 0.3s ease, visibility 0.3s ease;

    @media (max-width: 1160px) {
      flex-direction: column-reverse;
    }

    @media (max-width: 768px) {
      gap: 10px;
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      display: none;
    }

    &.is-active {
      // opacity: 1;
      // visibility: visible;
      // pointer-events: visible;

      display: flex;

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }

  &__reset-btn {
    border-radius: 70px;
    padding: 21px 30px;
    width: 301px;
    height: 70px;
    background: var(--base-secondary);

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--base-black);

    display: flex;
    align-items: center;
    gap: 20px;

    // opacity: 0;
    // visibility: hidden;
    // pointer-events: none;
    display: none;

    transition: opacity 0.3s ease, visibility 0.3s ease;

    @media (max-width: 1160px) {
      width: 373px;
      justify-content: space-between;
      font-size: 18px;
    }

    @media (max-width: 840px) {
      width: 340px;
    }

    @media (max-width: 768px) {
      border-radius: 30px;
      width: 290px;
      height: 50px;

      font-size: 16px;
      // opacity: 1;
      // visibility: visible;
      // pointer-events: visible;
      display: none;

      img {
        width: 20px;
        height: 20px;
      }
    }

    @media (max-width: 385px) {
      font-size: 12px;
      width: 225px;
    }

    &.is-active {
      // opacity: 1;
      // visibility: visible;
      // pointer-events: visible;
      display: flex;

      @media (max-width: 768px) {
        display: flex;
      }
    }

    img {
      flex-shrink: 0;
    }
  }

  &__category {
    .option {
      text-transform: uppercase;
    }

    .select-box {
      z-index: 12;
    }

    .options-wrapper {
      z-index: 11;
    }
  }
}
