.card {
  width: 526px;

  position: fixed;

  left: 20px;
  bottom: 30px;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  z-index: 1000;
  transition: all 0.3s ease-in-out;

  opacity: 0;
  visibility: hidden;

  @media (max-width: 768px) {
    max-width: 768px;
    width: 100%;
  }

  @media (max-width: 576px) {
    left: unset;
    top: unset;
    left: 0;
    transform: unset;
    bottom: 0;
    opacity: 1;
    visibility: visible;

    transform: translateY(100%);
  }

  &.is-active {
    opacity: 1;
    visibility: visible;

    @media (max-width: 576px) {
      transform: translateY(0);
    }
  }

  &__top {
    height: 48px;
    background-color: var(--base-main);
    display: flex;
    align-items: center;
    justify-content: end;

    @media (max-width: 768px) {
      height: 50px;
    }
  }

  &__content {
    max-height: 412px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 15px;
    background: var(--base-white);

    @media (max-width: 1160px) {
      max-height: 458px;
    }

    @media (max-width: 768px) {
      max-height: 527px;
      padding: 15px 96px;
    }

    @media (max-width: 576px) {
      padding: 15px;
      max-height: 529px;
    }
  }

  &__title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 15px;

    h2 {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: var(--base-main);

      @media (max-width: 1160px) {
        font-size: 20px;
      }

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 31px;
    height: 31px;
    border: 2px solid var(--base-black);
    border-radius: 50%;

    flex-shrink: 0;
  }

  &__desc {
    margin-bottom: 5px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--base-black);

    @media (max-width: 1160px) {
      font-size: 14px;
    }
  }

  &__link {
    margin-bottom: 20px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--base-secondary);
    transition: opacity 0.3s ease-in-out;
    display: block;

    @media (max-width: 1160px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--base-black);
  }

  &__video {
    iframe {
      width: 100%;
      height: 242px;

      @media (max-width: 576px) {
        height: 174px;
      }
    }
  }

  &__btn {
    width: 48px;
    height: 48px;
    background-color: var(--base-secondary);
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;

    transition: opacity 0.3s ease-in-out;

    cursor: pointer;

    @media (max-width: 768px) {
      width: 210px;
      height: 50px;
    }

    @media (max-width: 576px) {
      width: 50px;
      height: 50px;
    }

    img {
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
}
