.about {
  background: var(--base-white);
  padding: 150px 0;

  @media (max-width: 768px) {
    padding: 80px 0;
  }

  @media (max-width: 576px) {
    padding: 175px 0 80px;
    position: relative;
  }

  &__wrapper {
  }

  &__top {
    display: grid;
    grid-template-areas:
      'title img'
      'main-text img'
      'subtitle img'
      'features img';
    column-gap: 20px;
    grid-template-columns: minmax(600px, 928px) 1fr;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1440px) {
      grid-template-areas:
        'title img'
        'main-text img'
        'subtitle subtitle'
        'features features';

      grid-template-columns: minmax(550px, 667px) 1fr;
    }

    @media (max-width: 1160px) {
      grid-template-areas:
        'title title'
        'main-text img'
        'subtitle subtitle'
        'features features';
      grid-template-columns: minmax(320px, 468px) 1fr;
    }

    @media (max-width: 576px) {
      display: block;
      grid-template-columns: unset;
    }
  }

  &__title {
    margin-bottom: 35px;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: 92px;
    line-height: 110%;
    color: var(--base-main);

    grid-area: title;

    @media (max-width: 1440px) {
      font-size: 75px;
    }

    @media (max-width: 768px) {
      font-size: 64px;
    }

    @media (max-width: 576px) {
      font-size: 32px;
    }
  }

  &__main-text {
    grid-area: main-text;
    margin-bottom: 35px;

    @media (max-width: 1440px) {
      margin-bottom: 35px;
    }
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    line-height: 130%;
    color: var(--base-black);

    @media (max-width: 1160px) {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    margin-bottom: 20px;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: 40px;
    line-height: 110%;
    color: var(--base-main);

    grid-area: subtitle;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__features {
    margin-bottom: 35px;
    grid-area: features;
  }

  &__features-list {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    line-height: 130%;
    color: var(--base-black);

    @media (max-width: 1160px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__features-list-item {
    list-style: disc;
    list-style-position: inside;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__announce {
    margin-bottom: 35px;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: var(--base-main);

    @media (max-width: 768px) {
      font-size: 16px;
    }

    p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__plans {
    margin-bottom: 35px;
  }

  &__text--accent {
    margin-bottom: 35px;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: var(--base-main);

    // @media (max-width: 1160px) {
    //   margin-bottom: 20px;
    // }

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__img {
    grid-area: img;
    display: flex;
    justify-content: end;
    width: 100%;
    height: 543px;
    flex-shrink: 0;

    @media (max-width: 1440px) {
      height: 447px;
    }

    @media (max-width: 1160px) {
      width: 276px;
      height: 325px;
    }

    @media (max-width: 768px) {
      width: 170px;
      height: 100%;
      align-items: center;
    }

    img {
      width: 462px;
      height: 100%;
      object-fit: contain;

      @media (max-width: 1440px) {
        width: 380px;
        height: 447px;
      }

      @media (max-width: 1160px) {
        width: 276px;
        height: 325px;
      }

      @media (max-width: 768px) {
        width: 170px;
        height: 190px;
      }

      @media (max-width: 576px) {
        position: absolute;
        top: 20px;
        right: 0;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 70px;
    border-radius: 35px;
    padding: 10px 30px;
    width: 245px;
    height: 70px;
    background: var(--category-600);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--base-black);

    transition: background-color 0.3s ease, color 0.3s ease;

    @media (max-width: 1160px) {
      width: 100%;
      font-size: 18px;
      height: 67px;
    }

    @media (hover: hover) {
      &:hover {
        background: var(--map-utc10);
        color: var(--base-white);
      }
    }
  }
}
