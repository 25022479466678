.admin-auth {
  &__wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 576px) {
      display: block;
    }
  }
  &__title {
    margin-bottom: 35px;
    font-family: var(--second-family);
    font-weight: 900;
    font-size: 92px;
    line-height: 110%;
    color: var(--base-secondary);

    @media (max-width: 1440px) {
      font-size: 75px;
    }

    @media (max-width: 1160px) {
      font-size: 61px;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
      font-size: 44px;
    }

    @media (max-width: 576px) {
      font-size: 32px;
    }

    @media (max-width: 360px) {
      font-size: 26px;
    }
  }

  &__form {
    position: relative;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      width: 790px;

      @media (max-width: 1440px) {
        width: 666px;
      }

      @media (max-width: 1160px) {
        width: 479px;
      }

      @media (max-width: 768px) {
        width: 347px;
        gap: 15px;
      }

      @media (max-width: 576px) {
        width: 100%;
      }

      label {
        width: 100%;
        position: relative;
      }
      input {
        width: 100%;
        border: 2px solid var(--base-white);
        border-radius: 35px;
        padding: 21px 30px;
        height: 67px;

        font-family: var(--font-family);
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: var(--base-white);

        @media (max-width: 768px) {
          font-size: 16px;
          height: 50px;
          padding: 14px 30px;
        }

        &::placeholder {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: var(--base-white);

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      .admin-auth__icon-error {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        display: none;

        &.is-active {
          display: block;
        }
      }

      button {
        margin-top: 10px;
        border-radius: 35px;
        padding: 21px 30px;
        width: 100%;
        height: 70px;
        background: var(--base-main);
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: var(--font-family);
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        text-transform: uppercase;
        color: var(--base-white);

        @media (max-width: 768px) {
          font-size: 16px;

          height: 50px;
          margin-top: 5px;
        }

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .admin-auth__input-error {
        border: 2px solid #ff4649;
        &::placeholder {
          font-family: var(--font-family);
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          color: #ff4649;
        }
      }
    }
  }

  &__img {
    width: 462px;
    height: 543px;

    @media (max-width: 1440px) {
      width: 418px;
      height: 492px;
    }

    @media (max-width: 1160px) {
      width: 254px;
      height: 299px;
    }

    @media (max-width: 768px) {
      width: 205px;
      height: 241px;
    }
    @media (max-width: 576px) {
      position: absolute;
      width: 95px;
      height: 112px;

      top: 20px;
      right: 15px;
    }
  }

  &__error-message {
    position: absolute;
    bottom: 77px;
    color: #ff4649;

    @media (max-width: 768px) {
      font-size: 12px;
      bottom: 54px;
    }

    @media (max-width: 360px) {
      font-size: 10px;
    }
  }
}
