.not-found {
  padding: 150px 0;

  @media (max-width: 768px) {
    padding: 80px 0;
  }

  &__error-wrapper {
    margin-bottom: 60px;
    position: relative;
    height: 265px;

    @media (max-width: 1440px) {
      height: 233px;
    }

    @media (max-width: 1160px) {
      height: 243px;
    }

    @media (max-width: 768px) {
      margin-bottom: 50px;
      height: 165px;
    }

    @media (max-width: 576px) {
      margin-bottom: 40px;
      height: 88px;
    }
  }

  &__error {
    font-family: var(--second-family);
    font-weight: 900;
    font-size: 241px;
    line-height: 110%;
    color: var(--base-white);
    white-space: nowrap;
    position: absolute;
    left: 50%;

    transform: translateX(-50%);

    @media (max-width: 1440px) {
      font-size: 212px;
    }

    @media (max-width: 1160px) {
      font-size: 221px;
    }

    @media (max-width: 768px) {
      font-size: 149px;
    }

    @media (max-width: 576px) {
      font-size: 80px;
    }
  }

  &__message {
    max-width: 725px;
    margin: 0 auto 30px;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: var(--base-white);

    @media (max-width: 1160px) {
      font-family: var(--font-family);
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: var(--base-white);
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__link {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    padding: 14px 30px;
    width: 514px;
    height: 70px;
    background: var(--base-main);

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--base-white);

    @media (max-width: 1160px) {
      width: 376px;
      font-size: 18px;
    }

    @media (max-width: 768px) {
      width: 100%;

      font-size: 16px;
    }
  }
}
