@font-face {
  font-family: 'Roboto';
  src: url('./../fonts/Roboto-Regular.woff2') format('woff2'),
    url('./../fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./../fonts/Inter-Regular.woff2') format('woff2'),
    url('./../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./../fonts/Inter-Medium.woff2') format('woff2'),
    url('./../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Alternates';
  src: url('./../fonts/MontserratAlternates-Black.woff2') format('woff2'),
    url('./../fonts/MontserratAlternates-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./../fonts/Inter-Light.woff2') format('woff2'),
    url('./../fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('./../fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Alternates';
  src: url('./../fonts/MontserratAlternates-Bold.woff2') format('woff2'),
    url('./../fonts/MontserratAlternates-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('./../fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
