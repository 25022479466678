.admin-login {
  background: var(--base-bg);
  height: 100%;
  padding: 150px 0;

  @media (max-width: 768px) {
    padding: 80px 0;
     position: relative;

  }

  @media(max-width: 576px) {
    padding: 100px 0;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}
