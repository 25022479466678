.map-editor {
  &__wrapper {
    position: relative;
    margin-bottom: 30px;
  }

  .react-transform-wrapper.transform-component-module_wrapper__SPB86 {
    height: 936px;

    // .map-container {
    //   width: 1444px;
    //   height: 816px;
    // }

    // img {
    //   width: 1444px;
    //   height: 816px;
    // }
  }
}
