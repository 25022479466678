.logo-editor {
  padding: 40px 0 60px;

  &__edit {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    color: var(--base-black);
  }

  &__confirm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--base-black);
    color: var(--base-white);
    border-radius: 8px;
    padding: 12px 30px;

    width: 180px;
    height: 45px;

    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}
