.admin-sidebar {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-right: 1px solid #2c2c2c;
  min-height: calc(100vh - 282px);
  padding: 30px 0;

  &__category-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 250%;
    text-transform: uppercase;
    color: #2c2c2c;
  }

  &__list {
    gap: 10px;
    padding-right: 20px;
  }

  &__list-link {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 100%;
    display: inline-block;
    color: #706f6f;
    border-radius: 15px;

    &.active {
      background: #2c2c2c;
      color: #fff;
    }
  }

  &__logout-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}
