.map {
  cursor: grab;
  position: relative;
  height: 938px;

  height: calc(100vh - 120px);
  width: 100vw;

  @media (max-width: 1440px) {
    height: 790px;
    height: calc(100vh - 120px);
  }

  @media (max-width: 1160px) {
    height: 749px;
    // height: calc(100vh - 120px);
  }

  @media (max-width: 900px) {
    // @media (max-width: 768px) {
    height: 579px;
  }

  @media (max-width: 576px) {
    height: calc(90svh - 75px);
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #373e78;

    transition: background-color 0.3s ease;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.broadcasts-bg {
      background: #423781;
    }
  }

  &__map-wrapper {
    position: relative;
    width: 100%;
    height: 936px;
    overflow: hidden;

    max-width: 100vw;
  }

  &__map {
    padding-top: 60px;
    width: 100%;
    height: 938px;
    overflow: hidden;
    width: 100vw;
  }

  &__controls-wrapper {
    position: absolute;
    z-index: 10;
    bottom: 50px;
    right: 0;

    display: flex;
    align-items: center;
    gap: 20px;
    padding-right: max(20px, calc((100% - 1600px) / 2));

    @media (max-width: 1440px) {
      padding-right: max(20px, calc((100% - 1160px) / 2));
    }

    @media (max-width: 1160px) {
      padding-right: max(20px, calc((100% - 768px) / 2));
    }

    @media (max-width: 768px) {
      padding-right: max(20px, calc((100% - 576px) / 2));
      bottom: 20px;
    }

    @media (max-width: 576px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding-right: 15px;
    }
  }

  &__filter-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    width: 50px;
    height: 50px;

    background: var(--base-secondary);
    position: relative;

    display: none;

    @media (max-width: 768px) {
      display: flex;
    }

    &.is-active {
      .map__filter-btn--close {
        opacity: 1;
      }
    }

    &--close {
      position: absolute;
      background: var(--base-secondary);

      opacity: 0;

      transition: opacity 0.3s ease;
    }
  }

  &__controls {
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
      // align-items: flex-start;
      // padding-right: 15px;
    }
  }

  &__control-btn {
    height: 70px;
    background: var(--base-white);
    width: 123px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--plus {
      border-radius: 70px 0 0 70px;
      @media (max-width: 576px) {
        border-radius: 70px 70px 0 0;
      }
    }

    &--minus {
      border-radius: 0 70px 70px 0;
      @media (max-width: 576px) {
        border-radius: 0 0 70px 70px;
      }
    }

    @media (max-width: 1160px) {
      width: 113px;
      height: 67px;
    }

    @media (max-width: 768px) {
      height: 50px;
      width: 73px;
    }

    @media (max-width: 576px) {
      height: 73px;
      width: 50px;
    }
  }

  &__cities {
    position: absolute;
    width: 380px;
    max-height: 546px;
    overflow-y: scroll;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-content: flex-start;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    transition: opacity 0.6s ease, visibility 0.6s ease;
    &.is-active {
      opacity: 1;
      visibility: visible;
      pointer-events: unset;
    }
  }

  &__city {
    width: 34px;
    height: 34px;

    border-radius: 50%;
    border: 2px solid #2c2c2c;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-transform-wrapper.transform-component-module_wrapper__SPB86 {
    max-width: 1600px;
    height: 936px;
    margin: 0 auto;

    max-width: 100%;
    width: 100%;

    max-width: 100vw;
    width: 100vw;

    height: calc(100vh - 120px);

    @media (max-width: 1440px) {
      height: 790px;
      height: calc(100vh - 120px);
    }

    @media (max-width: 1160px) {
      height: 749px;
      // height: calc(100vh - 120px);
    }

    // @media (max-width: 768px) {
    @media (max-width: 900px) {
      max-width: unset;
      width: 100%;
      height: 579px;
      margin: 0;
    }

    @media (max-width: 576px) {
      height: calc(90svh - 75px);
    }
  }

  .transform-component-module_content__FBWxo {
    max-width: 1600px;
    max-width: 100%;

    // @media (max-width: 768px) {
    @media (max-width: 900px) {
      max-width: unset;
    }
  }

  &__svg-wrapper {
    position: relative;
    // max-width: 1600px;

    max-width: 100%;

    // @media (max-width: 786px) {
    @media (max-width: 900px) {
      width: 917px;
    }
    img {
      width: 1444px;

      width: 100%;

      height: 816px;
      height: calc(100vh - 120px);
      width: auto;
      max-width: 1600px;
      max-height: 816px;

      @media (max-width: 1440px) {
        // width: 1123px;
        // height: 634px;

        width: auto;

        height: calc(100vh - 120px);

        max-height: 634px;
      }

      @media (max-width: 1160px) {
        width: 917px;
        height: 518px;
      }

      // @media (max-width: 768px) {
      @media (max-width: 900px) {
        width: 917px;

        height: auto;
      }
    }
  }
}

.dot {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: 2px solid var(--base-black);
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}

.map-dot-broadcast {
  width: 31px;
  height: 31px;

  @media (max-width: 1160px) {
    width: 25px;
    height: 25px;
    border: 1.5px solid var(--base-black);
  }

  svg {
    @media (max-width: 1160px) {
      width: 12px;
      height: 12px;
    }
  }
}

.map-dot-cities {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #ebf5fa;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--base-black);

  transition: all 0.3s ease;

  @media (max-width: 1440px) {
    border: 1.5px solid var(--base-black);

    width: 42px;
    height: 42px;
  }

  @media (max-width: 1160px) {
    width: 35px;
    height: 35px;
  }

  span {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase;
    color: var(--base-black);

    @media (max-width: 1440px) {
      font-size: 15px;
    }

    @media (max-width: 1160px) {
      font-size: 12px;
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.map-dot-city {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--base-black);

  @media (max-width: 1440px) {
    width: 31px;
    height: 31px;
    border: 1.5px solid var(--base-black);
  }

  @media (max-width: 1160px) {
    width: 25px;
    height: 25px;
  }

  svg {
    @media (max-width: 1440px) {
      width: 14px;
      height: 14px;
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.map-dot-location {
  width: 34px;
  height: 34px;

  @media (max-width: 1440px) {
    width: 27px;
    height: 27px;
  }
}

.map__cities-block {
  padding: 20px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 394px;
  max-height: 546px;
  overflow-y: scroll;

  visibility: hidden;

  // transition: opacity 0.3s ease, visibility 0.3s ease;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}

.map-dot-location-test {
  width: 34px;
  height: 34px;
  position: relative;
}

.map__wrapper {
  position: relative;
}
