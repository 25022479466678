.partners {
  padding: 70px 0;
  background: var(--base-secondary);

  @media (max-width: 1160px) {
    padding: 50px 0;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__list-item {
    &:nth-child(1) .partners__img {
      @media (max-width: 1160px) {
        width: 46px;
        height: 46px;
      }

      @media (max-width: 768px) {
        width: 33px;
        height: 33px;
      }

      @media (max-width: 576px) {
        width: 25px;
        height: 25px;
      }
    }

    &:nth-child(2) .partners__img {
      @media (max-width: 1160px) {
        width: 181px;
        height: 64px;
      }

      @media (max-width: 768px) {
        width: 147px;
        height: 52px;
      }

      @media (max-width: 576px) {
        width: 110px;
        height: 38px;
      }
    }

    &:nth-child(3) .partners__img {
      @media (max-width: 1160px) {
        width: 226px;
        height: 66px;
      }

      @media (max-width: 768px) {
        width: 133px;
        height: 38px;
      }

      @media (max-width: 576px) {
        width: 100px;
        height: 29px;
      }
    }
  }
}
