:root {
  --category-0: #ebf5fa;
  --category-100: #ceebfa;
  --category-200: #b0e1fa;
  --category-300: #94d8fa;
  --category-400: #7acffa;
  --category-500: #63c7f9;
  --category-600: #4ec0f8;
  --category-700: #3db9f7;
  --category-800: #2eb2f5;
  --category-900: #21acf1;
  --category-1000: #17a5eb;
  --category-1100: #0f9ce2;
  --category-1200: #0990d4;
  --category-1300: #0481bf;
  --category-1400: #016da3;
  --category-live: #1c2566;
  --base-secondary: #6cf;
  --base-main: #6f7bd4;
  --base-black: #2c2c2c;
  --base-white: #f9f9ff;
  --base-bg: #373e78;
  --map-utc7: #b3baf8;
  --map-utc5: #d5dafc;
  --map-utc11: #4a56ad;
  --map-utc9: #7a86e6;
  --map-utc2: #7681d2;
  --map-utc12: #3e4684;
  --map-utc10: #6471d8;
  --map-utc4: #c9cfff;
  --map-utc8: #949ff1;
  --map-utc6: #a8b1ff;
  --map-utc3: #f0eaf7;
  --map-stroke: #282d57;
}

:root {
  --font-family: 'Inter', sans-serif;
  --second-family: 'Montserrat Alternates', sans-serif;
  --third-family: 'Roboto', sans-serif;
}
