.broadcasts-editor {
  padding: 40px 0;
  width: 100%;

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__list {
  }

  &__list-desc {
    padding: 20px 0;

    display: grid;

    gap: 20px;
    grid-template-columns:
      20px minmax(200px, 250px) minmax(320px, 420px) minmax(150px, 230px)
      minmax(150px, 230px) 150px;

    font-size: 18px;
    font-weight: 600;

    border-bottom: 1px solid var(--base-black);
  }

  &__list-item {
    padding: 20px 0;
    display: grid;
    gap: 20px;
    grid-template-columns:
      20px minmax(200px, 250px) minmax(320px, 420px) minmax(150px, 230px)
      minmax(150px, 230px) 150px;

    border-bottom: 1px solid var(--base-black);

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__btns {
    display: flex;
    gap: 10px;
  }
}
