html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-family);
  min-height: 100vh;
  width: 100%;
  height: 100%;

  &.no-scroll {
    overflow: hidden;
    // position: fixed;
    // width: 100%;
    @media (max-width: 576px) {
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
  }
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

main {
  flex-grow: 1;

  background: var(--base-bg);
}

.container {
  max-width: 1640px;
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: calc(1160px + 20px * 2);
  }

  @media (max-width: 1160px) {
    max-width: calc(768px + 20px * 2);
  }

  @media (max-width: 768px) {
    max-width: 576px;
    padding: 0 15px;
  }
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
